<template>
 <div class="searchbox flex">
   <div class="left">
      <div class="city"><img src="@img/location.png" alt="" width="20">{{currentcity}}</div>
      <!--  <img src="@img/arrow-d.png" alt="">
       <el-select v-model="value" placeholder="请选择" @change="selectcity" class="select">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select> -->
   </div>
   <div class="inputplace flex1">
      <input type="text" v-model="input" class="text">
   </div>
   <div class="right flexcenter" @click="search()">
搜索
   </div>
 </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
export default {
 props:{
    keyword: {
      type:String,
      default:""
    }
 },
  data() {
    return {
       input:this.keyword,
      //  options: [],
        // value: "",
        currentcity:""
    }
  },
  computed: {
     ...mapState(["isLogin", "userInfo"]),
  
  },
  watch:{
      keyword(newval,oldval){
       this.input=newval;
      }
  },
  methods: {
    selectcity(e){
     var num=this.options.findIndex((obj)=>{
       return obj.value==e
      })
      this.currentcity=this.options[num].label;
    },
    search(){
      this.$emit("input",this.input)
    }
  },
  mounted(){
  
    //  this.currentcity=this.options[0].label
     this.$http.location_city().then(res=>{
       this.currentcity=res.data.city
     })
  
  }
}
</script>
<style scoped lang="scss">
@import "@/styles/mixin.scss";
.select{
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.el-select-dropdown__item{
  font-size: 16px;
}
.searchbox{
  width: 100%;
  height: 60px;
  border: 1px solid $color-theme;
  border-radius: 3px;
  overflow: hidden;
  background-color: #fff;
  // margin-top: 20px;
  margin-bottom: 20px;
}
.left{
  border-right: 1px solid  $color-theme;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100%;
  cursor: pointer;
  position: relative;
  .city{
    margin-right: 8px;
    display: flex;
    align-items: center;
    img{
      margin-right: 8px;
    }
  }
}
.text{
  width: 100%;
  height: 100%;
  font-size: 20px;
  outline: none !important;
  padding-left: 15px;
}
.right{
   cursor: pointer;
  width: 100px;
  height: 100%;
  font-size: 18px;
  color: #fff;
  background-color: #1879f5;
}
</style>
