<template>
  <div class="container">
      <search-item @input="searchlist"></search-item>
    <div class="zxhd">
      <div class="item" v-for="(item, i) in list" :key="i" @click="openpage('/zxdetail',item.id)">
        <div class="left">
          <img :src="item.thumb" alt="暂无图片" />
        </div>
        <div class="right">
          <div class="name">{{ item.title }}</div>
           <div class="intro">
                  {{ item.desc }}
          </div>
          <div class="flex tip0 justify-between">
            <div class="date">{{item.rtime}}</div>
            <div class="num">{{item.pv}}人看过</div>
          </div>
        </div>
      </div>
      <div class="pagination" v-show="total>0">
        <el-pagination
    background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="5"
        layout="total, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
      </div>
        <el-empty :image-size="150" v-show="total==0"></el-empty>
    </div>
  </div>
</template>

<script>
import searchItem from "@c/common/searchitem.vue";
export default {
  components: {searchItem},
  data() {
    return {
      currentPage:1,
      total:0,
      list: [],
       keyword:""
    };
  },
  computed: {},
  mounted(){
  this.handleCurrentChange(this.currentPage)
  },
  methods: {
     searchlist(val){
      this.keyword=val;
      this.handleCurrentChange(this.currentPage)
    },
     handleCurrentChange(val) {
     
        this.$http.newslist({
        
          offset:(val-1),
          limit:5,
          keyword:this.keyword
        }).then(res=>{
          this.list=res.data.list;
          this.total=res.data.total;
            if(this.total==0){
             this.$message.info({ message: "暂无数据!" });
          }
        })
      },
       openpage(path,id){
         this.$router.push({
          path,
          query: {
            id,
          },
        })
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
.container {
  margin-bottom: 20px;
}
.pagination{
  background: #fff;
  text-align: right;
  padding: 20px 0;
}
.zxhd{
  .item{
    padding: 20px;
    background: #fff;
    border-bottom:1px solid rgba($color: #999, $alpha: 0.2) ;
    display: flex;
    .left{
      width: 300px;
      height: 220px;
      margin-right: 30px;
      border-radius: 8px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .right{
      flex: 1;
      position: relative;
      cursor: pointer;
      .name{
        font-size: 20px;
        font-weight: bold;
        margin:20px 0 ;
      }
      .intro{
        font-size: 16px;
        line-height: 1.5;
        color: #666;
        @include multi-line-ellipsis(4)
      }
      .tip0{
         color: #666;
        position: absolute;
        bottom: 0px;
        width: 100%;
        font-size: 16px;
      }
    }
  }
}

</style>
